import React from 'react'
import './Hero.css'

export default function Hero() {

  const handleClickScroll = (id) => {
    const element = document.getElementById(id); // Find the element by ID
    if (element) {
        const offset = -100; // Adjust for the fixed navigation height
        const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
        const offsetPosition = elementPosition + offset;

        window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth',
        });
    }
};

  return (
    <div id='hero'>
      <div id='hero-container'>
        <div id='hero-info'>
          <h1>Business Growth Partners</h1>
          <p style={{ color: 'white' }}>We provoke growth by shaping customer experiences through media, content and technology.</p>
          <button className='white-btn' onClick={() => handleClickScroll("about")}>Learn More</button>
        </div>
      </div>
    </div>
  )
}
