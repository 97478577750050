import React from 'react'
import saf from '../Assets/saf2.jpg'

export default function ThingsWeCover() {

  const handleClickScroll = (id) => {
    const element = document.getElementById(id); // Find the element by ID
    if (element) {
        const offset = -100; // Adjust for the fixed navigation height
        const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
        const offsetPosition = elementPosition + offset;

        window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth',
        });
    }
};

  return (
    <div>
        <div className='info-pic-container' >
            <div className='horizontal-info'>
                <div className='pink-header' style={{margin: '30px 0'}}>
                    <h2>Things we cover</h2>
                </div>
                <p style={{fontWeight: 700}}>We help our partners grow by offering a comprehensive and tailored marketing approach.</p>
                <p style={{marginTop: 30}}>Our process includes an in-depth audit, customised strategy, precise implementation, continuous optimisation, and detailed reporting.</p>
                <p style={{marginTop: 30}}>This ensures that your brand not only stands out but thrives in a competitive market. As growth partners we are here for your every need, we aren’t limited to specific things but work closely with you to reach your end goal.</p>
                <button style={{marginTop: 30}} className='pink-btn' onClick={() => handleClickScroll("services")}>Learn More</button>
            </div>
            <img src={saf} className='info-img'/>
        </div>
    </div>
  )
}
