import React from 'react'
import saf from '../Assets/saf1.jpg'
import './Academy.css'

export default function Academy() {

    
  return (
    <div className='beige' >
      <div className='info-pic-container' >
        <div className='horizontal-info'>
            <div className='yellow-header'>
                <h2 style={{whiteSpace: 'nowrap'}}>S. The Academy­</h2>
            </div>

            <p className='top-margin' style={{ maxWidth: 500}}>With a solid background in education spanning over six years within the advertising and media sector, I've channelled my passion for nurturing the younger generation into the creation of S.theacademy.</p>

            <p style={{marginTop: 50, maxWidth: 500}}>Our academy serves as the key to unlocking boundless potential. Please take a look at our latest project.</p>
            <a href='https://advidity.app/' target='_blank'>
              <button className='black-btn' style={{marginTop: 50}}>Learn More</button>
            </a>
        </div>

        <img src={saf} className='info-img'/>
      </div>
    </div>
  )
}
