import React from 'react';
import saf from '../Assets/saf1.jpg';
import Accordion from './Accordion';

export default function Values() {
  return (
    <div className='beige'>
      <div className='info-pic-container' style={{ padding: 0 }}>
        {/* Fixed-width image */}
        <img src={saf} className='info-img' />

        {/* Flex container with accordion */}
        <div className='horizontal-info'>
          <div className='yellow-header'>
            <h2>Our Values</h2>
          </div>
          <Accordion />
        </div>
      </div>
    </div>
  );
}
