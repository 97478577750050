import React from 'react'

export default function GrowthPartners() {
  return (
    <div className='beige' id='about'>
        <div className='info-container'>
            <div className='yellow-header'>
                <h2>What is a Business Growth Partner?­</h2>
            </div>

            <p className='top-margin' style={{width: 1000, maxWidth: '100%', fontWeight: 700}}>Think of us as your external marketing department, a dedicated partner committed to strengthening your brand. We build robust relationships, tailored to your unique business requirements.</p>

            <div style={{display:'flex', flexWrap:'wrap', marginBottom: 50, justifyContent:'space-between', maxWidth: 1000}}>
              <p className='top-margin' style={{width: 450, maxWidth: '100%'}}>Our approach goes beyond the traditional focus on impact and reach. We craft solutions that not only tackle business challenges but also forge brand salience and pave the way for a brighter future.</p>
              <p className='top-margin' style={{width: 450, maxWidth: '100%'}}>Whether you prefer guidance, consultation, or full-scale execution, we've got you covered. We invest time in comprehending not only your marketing needs but also the intricacies of your business, ensuring our strategies align seamlessly with your needs.</p>
            </div>

            <button className='black-btn'>Contact Us</button>
        </div>
    </div>
  )
}
