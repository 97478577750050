import React, { useRef } from 'react';
import img1 from '../Assets/experience1.webp';
import img2 from '../Assets/experience2.webp';
import img3 from '../Assets/experience3.webp';
import left from '../Assets/arrow-left.png';
import right from '../Assets/arrow-right.png';

export default function Experience() {
  const scrollerRef = useRef(null);

  const scrollLeft = () => {
    if (scrollerRef.current) {
      scrollerRef.current.scrollBy({ left: -250, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    if (scrollerRef.current) {
      scrollerRef.current.scrollBy({ left: 250, behavior: 'smooth' });
    }
  };

  return (
    <div className='pink' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
      <div className='pink-header'>
        <h2 style={{ color: 'white' }}>Our Experience</h2>
      </div>
      <div
        id='experience-scroller'
        ref={scrollerRef}
        style={{
          display: 'flex',
          overflowX: 'auto',
          overflowY: 'hidden',
          gap: '10px',
          width: '100vw', /* Adjust width as needed */
          scrollBehavior: 'smooth'
        }}
      >
        <img style={{ height: 220 }} src={img1} alt='S The Agency Marketing Clients' />
        <img style={{ height: 220 }} src={img2} alt='S The Agency Marketing Clients' />
        <img style={{ height: 220 }} src={img3} alt='S The Agency Marketing Clients' />
      </div>
      <div style={{ display: 'flex', marginTop: '10px' }}>
        <img onClick={scrollLeft} style={{ height: 40, paddingRight: 15, cursor: 'pointer' }} src={left} alt='Scroll Left' />
        <img onClick={scrollRight} style={{ height: 40, paddingLeft: 15, cursor: 'pointer' }} src={right} alt='Scroll Right' />
      </div>
    </div>
  );
}
