import React from 'react'

const benefits = [
    {number: '01', title: "Experience, Guidance & Support", info: "Ensuring your strategies are effective and aligned with your goals."},
    {number: '02', title: "Flexibility", info: "Allows for adaptable solutions tailored to your specific needs."},
    {number: '03', title: "Strong Communication", info: "Keeps you informed and involved."},
    {number: '04', title: "Fully Managed Day-to-Day Marketing Tasks", info: "You can focus on core operations."},
]

const benefits2 = [
    {number: '05', title: "Expertise & Knowledge", info: "We do all the heavy lifting so you don’t have too."},
    {number: '06', title: "Campaign Efficiency", info: "We make sure we hit every target, nothing goes under the radar."},
    {number: '07', title: "Results", info: "The proof is in the pudding."},
]

export default function Benefits() {
  return (
    <div className='beige'>
        <div className='info-container'>
            <div className='yellow-header'>
                <h2>BENEFITS OF HAVING A GROWTH PARTNER­</h2>
            </div>

            <div style={{display:'flex', flexWrap: 'wrap', justifyContent: 'space-between', maxWidth: 1100}}>
                <div>
                    {benefits.map((benefit) => {
                        return (
                        <div key={benefit.number} style={{display:'flex', marginTop: 40, maxWidth: 450}}>
                            <h3 className='benefit-number'>{benefit.number}.</h3>
                            <div>
                                <p style={{fontWeight: 700, lineHeight: 1}}>{benefit.title}</p>
                                <p style={{marginTop: 10}}>{benefit.info}</p>
                            </div>
                        </div>
                        )
                    })}
                </div>
                <div>
                {benefits2.map((benefit) => {
                        return (
                        <div key={benefit.number} style={{display:'flex', marginTop: 40, maxWidth: 450}}>
                            <h3 className='benefit-number'>{benefit.number}.</h3>
                            <div>
                                <p style={{fontWeight: 700, lineHeight: 1}}>{benefit.title}</p>
                                <p style={{marginTop: 10}}>{benefit.info}</p>
                            </div>
                        </div>
                        )
                    })}
                </div>
            </div>

           
        </div>
    </div>
  )
}
