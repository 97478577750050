import React, { useState } from 'react'
import arrow from '../Assets/arrow.png';
import './Accordion.css'

const values = [
    {name: "Making a Difference", info: "Impacting our partners is at the heart of what we do - we offer our knowledge and resources to lighten the load. Internally, we are passionate about providing transformative opportunities as our team grows."},
    {name: "Innovation", info: "We nurture innovation as a force for growth, always seeking better ways to enhance services and provide pioneering solutions. In 2023 we were awarded an Innovate UK award highlighting our commitment to push boundaries within our industry."},
    {name: "Alignment", info: "Prioritising unity ensures coherent collaboration with our partners. Our alignment, grounded in faith, is a key driver of our success & as we progress, both will remain fundamental to our ethos."},
    {name: "Abundance", info: "Abundance fuels our operations. Through cultivating gratitude and generosity, this mindset uplifts both our team and our partners as well as shaping our approach, empowering us to navigate challenges with resilience and embrace opportunities with open arms"},
    {name: "Living Purposefully", info: "We approach every endeavour with intention and meaning, cultivating a culture of purpose-driven work that fuels fulfilment and inspires positive change."},
]

export default function Accordion() {

    const [openValue, setOpenValue] = useState(null)

    const toggleAccordion = (index) => {
        setOpenValue(openValue === index ? null : index);
    }

  return (
    <div className="accordion">
        {values.map((value, i) => {
            return (
                <div key={i}>
                {/* Accordion Header */}
                <div
                  className="accordion-header"
                  onClick={() => toggleAccordion(i)}
                  style={{ padding: '30px 0' }}
                >
                  <p style={{fontWeight: 700}}>{value.name}</p>
                  <img
                    src={arrow}
                    className={openValue=== i ? 'arrow-open' : 'arrow-closed'}
                    alt="Toggle Arrow"
                  />
                </div>
      
                {/* Accordion Body */}
                <div
                  className={`accordion-body ${openValue === i ? 'open' : 'collapsed'}`}
                >
                  <div className="accordion-content">
                    <p>{value.info}</p>
                  </div>
                </div>
              </div>
            )
        })}
       
    </div>
  )
}
