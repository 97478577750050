import React from 'react'
import Hero from '../Hero'
import Experience from '../Experience'
import ThingsWeCover from '../ThingsWeCover'

export default function HomePage() {
  return (
    <div>
        <Hero />
        <Experience />
        <ThingsWeCover />
        
    </div>
  )
}
