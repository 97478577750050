import React from 'react'
import GrowthPartners from '../GrowthPartners'
import Benefits from '../Benefits'
import Scroller from '../Scroller'
import Process from '../Process'
import Values from '../Values'

export default function About() {
  return (
    <div>
        <GrowthPartners />
        <Scroller />
        <Benefits />
        <Process />
        <Values />
        
    </div>
  )
}
