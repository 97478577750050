import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import HomePage from './Components/HomePage/HomePage';
import About from './Components/About/About';
import Services from './Components/Services/Services';
import ScrollToTop from './Components/ScrollToTop';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <BrowserRouter>
      <ScrollToTop />
        <Routes>
          <Route path="/" element={<App />}  >
              <Route index element={<HomePage />}/>
              <Route path='about' element={<About />}/>
              <Route path='services' element={< Services/>}/>
          </Route>
        </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
