import React, { useRef, useState} from 'react'
import './Contact.css'
import emailjs from '@emailjs/browser';
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

export default function Contact() {

  const formRef = useRef();
  const nameRef = useRef();
  const emailRef = useRef();
  const numberRef = useRef();
  const msgRef = useRef();

  const [ loading, setLoading ] = useState(false)

  const sendEmail = (e) => {
    e.preventDefault();

    if(!nameRef.current.value || !numberRef.current.value || !emailRef.current.value || !msgRef.current.value ){
      toast.error("Please fill in all required fields")
    } else {
      setLoading(true)
      emailjs.sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, formRef.current, process.env.REACT_APP_PUBLIC_KEY)
      .then((result) => {
          formRef.current.reset();
          toast.success("Sent Successfully")
          setLoading(false)
      }, (error) => {
          toast.error("Oops, something went wrong")
          setLoading(false)
      });
    }
  };
  return (
    <div className='pink' style={{display:'flex', justifyContent:'center', flexDirection:'column', alignItems:'center'}} id='contact'>
      <div className='pink-header'>
        <h2 style={{color:'white'}}>Get in touch</h2>
      </div>

      <p style={{color: 'white', textAlign:'center', marginBottom: 30, width: 450, maxWidth: '100%'}}>Want to get in touch? Fill in your name and email and one of our team will get back to you!</p>

      <form ref={formRef} onSubmit={sendEmail}>
          <input placeholder='Name' ref={nameRef} name='Name'/>
          <input placeholder='Email' ref={emailRef} name='Email'/>
          <input placeholder='Number' ref={numberRef} name='Number'/>
          <textarea placeholder='Message' ref={msgRef} name='Msg'/>
          <button className='white-btn' type='submit' disabled={loading}>{loading ? "Sending..." : "Submit"}</button>
        </form>
    </div>
  )
}
