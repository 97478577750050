import React, { useState, useEffect} from 'react'
import './Navbar.css'
import logo from '../Assets/logo.png'
import { a } from 'react-router-dom';

export default function Navbar() {

    const [open, setOpen] = useState(false);

    useEffect(() =>{
      document.body.style.overflow = open ? "hidden" : "unset"
    }, [open]);
  
 
    const toggleNav = () => {
      setOpen(!open)
    }

    const handleClickScroll = (id) => {
      setOpen(false); // Close the mobile menu
      const element = document.getElementById(id); // Find the element by ID
      if (element) {
          const offset = -100; // Adjust for the fixed navigation height
          const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
          const offsetPosition = elementPosition + offset;
  
          window.scrollTo({
              top: offsetPosition,
              behavior: 'smooth',
          });
      }
  };
  

  return (
    <header className='not-scrolling'>

        <img style={{width: 275, maxWidth: '50vw'}} src={logo}/>

        <nav id='desktop-nav' >
            <a className='desktop-a' onClick={() => handleClickScroll('hero')}>home </a>
            <a className='desktop-a' onClick={() => handleClickScroll('about')}>about </a>
            <a className='desktop-a' onClick={() => handleClickScroll('services')}>services</a>
            <a className='desktop-a' onClick={() => handleClickScroll('contact')}>contact</a>
        </nav>

        <nav id='mobile-nav' className={open ? 'nav-open': 'nav-closed'}>
            <a className='mobile-a' onClick={() => handleClickScroll('hero')}>home </a>
            <a className='mobile-a' onClick={() => handleClickScroll('about')}>about </a>
            <a className='mobile-a' onClick={() => handleClickScroll('services')}>services</a>
            <a className='mobile-a' onClick={() => handleClickScroll('contact')}>contact</a>
        </nav>


        <div id='burger' onClick={toggleNav}>
          <div className={open ? 'burger-top-open' : 'burger-closed'}/>
          <div style={open ? {opacity: 0, transition: '1s'}: {width: '80%'}} className={open ? 'burger-open' : 'burger-closed'} />
          <div className={open ? 'burger-bottom-open' : 'burger-closed'} />
        </div>
        
    </header>
  )
}
