import React from 'react'
import deal from '../Assets/icons/deal.png'
import social from '../Assets/icons/social-media.png'
import ooh from '../Assets/icons/ooh.png'
import press from '../Assets/icons/press.png'
import campaigns from '../Assets/icons/campaigns.png'

const serviceList = [
    {name: 'Campaign Management ', icon: campaigns, info: 'Our core offering is monitoring all campaign activity looking at everything from content to paid media and partnerships to take your brand to the next level.'},
    {name: 'Social Media', icon: social, info: 'Social media content is everywhere, we ensure all content is meaningful and authentic to your brand creating content that people want to engage with.'},
    {name: 'Paid Digital Advertising', icon: ooh, info: 'We maximise your brands reach and awareness in strategic environments limiting wastage and ensuring we cut through the noise.'},
    {name: 'Brand Partnerships ', icon: deal, info: 'We have extensive experience with identifying mutually beneficial partnerships across brands by thoroughly understanding audiences and trends.'},    
    {name: 'PR', icon: press, info: 'We use our connections to make sure your news lands on the laps we need it to.'},    
]

export default function PopularServices() {

    const handleClickScroll = (id) => {
        const element = document.getElementById(id); // Find the element by ID
        if (element) {
            const offset = -100; // Adjust for the fixed navigation height
            const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
            const offsetPosition = elementPosition + offset;
    
            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth',
            });
        }
    };

  return (
    <div className='beige' id='services'>
          <div className='info-container'>
            <div className='yellow-header'>
                <h2 >Our Most Popular Services­</h2>
            </div>

            {serviceList.map((service, i) => {
                return (
                    <div key={i} style={{display: 'flex', margin: '70px 0'}}>
                        <img style={{height: 50, width: 50, objectFit:'contain', marginRight: 20}} src={service.icon} alt='S The Agency growth partner Service in Birmingham'/>
                        <div>
                            <p style={{fontWeight: 700}}>{service.name}</p>
                            <p>{service.info}</p>
                        </div>
                    </div>
                )
            })}

            <button className='black-btn' style={{width: 200}} onClick={() => handleClickScroll("contact")}>Work With Us</button>
        </div>
    </div>
  )
}
