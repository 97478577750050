import React from 'react'
import './Footer.css'
import linkedin from '../Assets/linkedin.png'
import insta from '../Assets/insta.png'
import logo from '../Assets/logo.png'

export default function Footer() {
  return (
    <footer>
        <div>
          <p style={{color:'white', marginBottom: 10}}>Sign up to our newsletter</p>
          <input placeholder='Email Address'/>
          <button className='white-btn'>submit</button>
        </div>


        <div style={{textAlign:'center'}}> 
          <img  src={logo} style={{width: 300, maxWidth: '100%', margin: '30px 0'}}/>
          <div>
            <a href='https://www.linkedin.com/company/76571866' target={'_blank'}>
              <img id='linkedin' src={linkedin}/>
            </a>
            <a href='https://www.instagram.com/stheagency' target={'_blank'}>
              <img id='insta' src={insta}/>
            </a>
          </div>
        </div>

        <div style={{textAlign:'right'}}>
          <p style={{color:'white'}}>Our Blog</p>
          <p style={{color:'white'}}>Terms & Conditions</p>
          <p style={{color:'white', marginBottom: 30}}>Privacy</p>
        </div>
    </footer>
  )
}
