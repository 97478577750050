import React from 'react';
import './Scroller.css';

let itemsToScroll = [
    "Strategy",
    "Social Media Management",
    "Branding Partnerships",
    "Influencers",
    "PR",
    "Campaign Execution",
    "Paid Social",
    "TV Advertising",
    "Outdoor Advertising",
    "Podcast Advertising",
    "SEO",
    "Display Advertising",
    "Strategy",
    "Social Media Management",
    "Branding Partnerships",
    "Influencers",
    "PR",
    "Campaign Execution",
    "Paid Social",
    "TV Advertising",
    "Outdoor Advertising",
    "Podcast Advertising",
    "SEO",
    "Display Advertising",
];

export default function Scroller() {
  return (
    <div id='scroller'>
      <div className='scroller-content'>
        {itemsToScroll.map((item, i) => (
          <p key={`item-${i}`} className='scroller-item'>{item}</p>
        ))}
        {/* Duplicate items for continuous scroll effect */}
        {itemsToScroll.map((item, i) => (
          <p key={`item-duplicate-${i}`} className='scroller-item'>{item}</p>
        ))}
      </div>
    </div>
  );
}
