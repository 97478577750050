import './App.css'
import React from 'react'
import {  ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import Hero from './Components/Hero';
import Navbar from './Components/Navbar';
import AboutUs from './Components/AboutUs';
import WhatWeDo from './Components/WhatWeDo';
import Contact from './Components/Contact';
import Footer from './Components/Footer';
import Overview from './Components/Overview';
import { register } from 'swiper/element/bundle';
import Academy from './Components/Academy';
import Landing from './Components/Landing';
import GrowthPartners from './Components/GrowthPartners';
import PopularServices from './Components/PopularServices';
import Experience from './Components/Experience';
import Benefits from './Components/Benefits';
import Scroller from './Components/Scroller';
import Process from './Components/Process';
import Values from './Components/Values';
import Team from './Components/Team';
import GetStarted from './Components/GetStarted';
import Instagram from './Components/Instagram';
import WhatYouWant from './Components/WhatYouWant';
import NeedToKnowMore from './Components/NeedToKnowMore';
import ThingsWeCover from './Components/ThingsWeCover';
// register Swiper custom elements
register();

function App() {
  
  return (
    <div >
      <ToastContainer position='top-center'/>
      <Navbar />
      <Hero />
      <GrowthPartners />
      <Experience />
      <ThingsWeCover />
      <Benefits />
      <Scroller />
      <PopularServices />
      <div style={{textAlign:'center', padding: '30px 0'}} className='mobile-remove'>
        <div className='pink-header' style={{margin: '30px auto'}}>
          <h2>Ready to get started?</h2>
        </div>
      </div>
      <GetStarted />
      <Process />
      <Values />
      {/*<Team />*/}
     
      <Contact />
      <Academy />
      {/*<Instagram/>*/}   
      <Footer />
    </div>
  );
}

export default App;
