import React, { useState, useEffect } from 'react';
import left from '../Assets/arrow-left.png';
import right from '../Assets/arrow-right.png';

export default function Process() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [stepWidth, setStepWidth] = useState(480); // Default step width

  const processes = [
    {
      step: '01',
      name: "Audit",
      info: "We begin by conducting a thorough audit of your current marketing efforts, analysing your website, social media presence, PR and press, and all forms of advertising above and below the line. This step allows us to identify strengths, weaknesses, and areas for improvement, ensuring that our strategies are tailored to your specific needs and goals.",
    },
    {
      step: '02',
      name: "Strategy",
      info: "We develop a comprehensive marketing strategy that aligns with your business objectives. To do this we carry out audience research and then carefully hand pick channels for your audience from both above and below the line. This bespoke approach ensures that your marketing efforts are cohesive, targeted, and effective.",
    },
    {
      step: '03',
      name: "Build Out",
      info: "This may involve optimising your existing assets or starting from scratch, creating compelling content and advertising streams to establish a strong presence both online and offline. We work diligently to bring your vision and purpose to life.",
    },
    {
      step: '04',
      name: "Optimise",
      info: "We closely monitor the performance of your campaigns by analysing data then making data-driven adjustments to ensure maximum impact and ROI. We stay flexible, adapting to the forever changing market conditions and consumer behaviour to keep your marketing and advertising efforts at the forefront of innovation.",
    },
    {
      step: '05',
      name: "Report and Thrive",
      info: "Transparency and accountability are key to our partnership. We provide regular, comprehensive reports that showcase key metrics, and areas for further improvement. This step not only keeps you informed but also allows us to celebrate your business's growth and identify new opportunities for continued success.",
    },
  ];

  // Update stepWidth dynamically based on screen size
  useEffect(() => {
    if(window.innerWidth > 480 ){
      setStepWidth(480)
    } else {
      const updateStepWidth = () => {
        const containerWidth = document.getElementById('process-wrapper').offsetWidth; // Get actual container width
        const padding = 16 * 2; // 1rem = 16px, so 2 * 1rem for both sides
        setStepWidth(window.innerWidth - 32); // Subtract padding and margin-right
      };
      // Initialize on mount and resize
      updateStepWidth();
      window.addEventListener('resize', updateStepWidth);
      return () => window.removeEventListener('resize', updateStepWidth);
    }

  }, []);


  const nextStep = () => {
    if (currentIndex < processes.length - 1) {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    }
  };

  const prevStep = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevIndex) => prevIndex - 1);
    }
  };

  return (
    <div className='pink' id='process'>
      <div className='info-container'>
        <div className='pink-header'>
          <h2 style={{ color: 'white' }}>Our Process</h2>
        </div>
        {console.log(currentIndex, stepWidth)}

        <div id='process-wrapper'>
          <div
            id='process-slider'
            style={{
              transform: `translateX(-${currentIndex * stepWidth}px)`,
              width: `${processes.length * stepWidth}px`,
            }}
          >
            {processes.map((step, i) => (
              <div key={i} className='process-step'>
                <h3
                  style={{
                    color: '#FF5693',
                    fontStyle: 'italic',
                    fontSize: 36,
                    marginRight: 10,
                    lineHeight: 1,
                    fontWeight: 600
                  }}
                >
                  {step.step}.
                </h3>
                <div style={{ width: 450, maxWidth: '100%' }}>
                  <p style={{ fontWeight: 700, color: 'white' }}>{step.name}</p>
                  <p style={{ color: 'white' }}>{step.info}</p>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
          <img
            style={{ height: 40, paddingRight: 15, cursor: 'pointer' }}
            src={left}
            alt="Previous"
            onClick={prevStep}
          />
          <img
            style={{ height: 40, paddingLeft: 15, cursor: 'pointer' }}
            src={right}
            alt="Next"
            onClick={nextStep}
          />
        </div>
      </div>
    </div>
  );
}
